import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SearchModel} from "@portal/shared/models/search-model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {StorageService} from "@portal/shared/services/storage.service";
import {TuningService} from "@portal/api/services/tuning-service";
import {Options} from '@angular-slider/ngx-slider';
import {TuningResponse} from "@portal/api/requests/tuning/tuning-response";
import {Guid} from "guid-typescript";
import {MatDialog} from "@angular/material/dialog";
import {DeleteDialogComponent} from "@portal/shared/delete-dialog/delete-dialog.component";

@Component({
    selector: 'app-tuning',
    templateUrl: './tuning.component.html',
    styleUrls: ['./tuning.component.scss']
})
export class TuningComponent implements OnInit {
    file: any;
    data: any;
    dataName: SearchModel;
    form: FormGroup;
    originalFileName: string = '';
    modifiedFileName: string = '';
    isLoadingSpinnerActive: boolean;
    formSubmitted: boolean;
    @ViewChild('originalFileInput') originalFileInput: ElementRef;
    @ViewChild('modifiedFileInput') modifiedFileInput: ElementRef;
    options: Options = {
        floor: -20,
        ceil: 20,
    };
    sliders: TuningResponse[];

    constructor(
        private _matDialog: MatDialog,
        private _router: Router,
        protected fb: FormBuilder,
        private _tuningService: TuningService,
        private _storageService: StorageService) {
    }

    ngOnInit(): void {
        this.data = this._storageService.getCurrentSearchData();
        this.dataName = this._storageService.getCurrentSearchNameData();
        if (this.data === null) {
            this._router.navigate(['/']);
        }
        this.form = this.fb.group({
            originalFile: new FormControl(null, [Validators.required]),
            modifiedFile: new FormControl(null, [Validators.required]),
            brandId: [this.data.brandId, [Validators.required]],
            ecuProducerId: [this.data.ecuProducerId, [Validators.required]],
            ecuTypeId: [this.data.ecuTypeId, [Validators.required]],
        });
    }

    onFileSelected(event: Event, name: string): void {
        const file = (event.target as HTMLInputElement).files[0];
        if (file) {
            if (name === 'original') {
                this.originalFileName = file.name;
                this.form.get('originalFile').setValue(file);

            } else {
                this.modifiedFileName = file.name;
                this.form.get('modifiedFile').setValue(file);
            }
        }
    }

    triggerFileInput(name: string): void {
        if (name === 'original') {
            this.originalFileInput.nativeElement.click();
        } else {
            this.modifiedFileInput.nativeElement.click();
        }
    }

    save() {
        if (this.form.valid) {
            const formData = new FormData();
            formData.append('originalFile', this.form.get('originalFile').value);
            formData.append('modifiedFile', this.form.get('modifiedFile').value);
            formData.append('brandId', this.data.brandId);
            formData.append('ecuProducerId', this.data.ecuProducerId);
            formData.append('ecuTypeId', this.data.ecuTypeId);
            this.file = this.form.get('modifiedFile').value;
            // submit and download the file
            this.isLoadingSpinnerActive = true;

            // search on tuning service and catch error
            this._tuningService.search(formData).subscribe((response) => {
                this.isLoadingSpinnerActive = false;
                this.sliders = response;
                this.formSubmitted = true;
            }, error => {
                this.isLoadingSpinnerActive = false;
                // mat dialog
                this._matDialog.open(DeleteDialogComponent, {
                    data: {
                        description: error.error.message,
                        hasOkButton: true,
                        type: 'error'
                    }
                });
            });

        }
    }

    submit() {
        if (this.form.valid) {
            const formData = new FormData();
            formData.append('modifiedFile', this.file);

            this.sliders.map((slider, index) => {
                formData.append(`request[${index}].name`, slider.name);
                formData.append(`request[${index}].range`, slider.range.toString());
                slider.values.map((value, valueIndex) => {
                    formData.append(`request[${index}].values[${valueIndex}].settingId`, value.settingId.toString());
                    formData.append(`request[${index}].values[${valueIndex}].addressInt`, value.addressInt.toString());
                    formData.append(`request[${index}].values[${valueIndex}].size`, value.size.toString());
                    formData.append(`request[${index}].values[${valueIndex}].dataType`, value.dataType.toString());
                    value.values.forEach((val, valIndex) => {
                        formData.append(`request[${index}].values[${valueIndex}].values[${valIndex}]`, val.toString());
                    });

                    value.floatValues.forEach((val, valIndex) => {
                        formData.append(`request[${index}].values[${valueIndex}].floatValues[${valIndex}]`, val.toString());
                    });
                });
            });

            // submit and download the file
            this.isLoadingSpinnerActive = true;
            this._tuningService.submit(formData).subscribe((response) => {
                this.formSubmitted = true;
                this.isLoadingSpinnerActive = false;
                // download the file
                const blob = new Blob([response.body], {type: 'application/octet-stream'});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'file.bin';
                link.click();
                window.URL.revokeObjectURL(url);

            });
        }
    }

    sliderChange(value: number, id: Guid) {
        // update the value of the slider in the sliders array
        this.sliders.map(slider => {
            if (slider.id === id) {
                slider.range = value;
            }
        });
    }
}
