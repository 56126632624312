import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DtcModuleService} from "@portal/api/services/dtc-module-service";
import {StorageService} from "@portal/shared/services/storage.service";
import {SearchModel} from "@portal/shared/models/search-model";
import {catchError, of} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {SignatureData} from "@portal/api/responses/dtc-removal/signature-data";
import {MapType} from "@portal/api/enums/map-type";
import {MatDialog} from "@angular/material/dialog";
import {DeleteDialogComponent} from "@portal/shared/delete-dialog/delete-dialog.component";
import {EnumNamesService} from "@portal/shared/services/enum-names.service";
import {reflectTypeEntityToDeclaration} from "@angular/compiler-cli/src/ngtsc/reflection";

@Component({
    selector: 'app-dtc-removal',
    templateUrl: './dtc-removal.component.html',
    styleUrls: ['./dtc-removal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DtcRemovalComponent implements OnInit, AfterViewInit {
    data: any;
    dataName: SearchModel;
    form: FormGroup;
    formErrors: FormGroup;
    filePassed: boolean;
    @ViewChild('fileInput') fileInput: ElementRef;
    fileName: string = '';
    isLoadingSpinnerActive: boolean;
    errors: string[] = [];
    signatureData: SignatureData;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    @ViewChildren('mapContainer') mapContainers: QueryList<ElementRef<HTMLDivElement>>;
    private scrollMapping = new Map<EventTarget, ElementRef<HTMLDivElement>>();
    lastErrorClicked: string;
    lastErrorIndexScrolled: number;
    lastErrorIndexScrolledManufacturer: number | null = null;
    lastErrorIndexScrolledDescriptions: number | null = null;
    currentTable: 'manufacturer' | 'descriptions' = 'manufacturer';

    constructor(
        public dialog: MatDialog,
        private _router: Router,
        protected fb: FormBuilder,
        private _enumService: EnumNamesService,
        private _dtcModuleService: DtcModuleService,
        private _storageService: StorageService) {
    }

    ngAfterViewInit(): void {
        this.mapContainers.forEach((container) => {
            this.scrollMapping.set(container.nativeElement, container);
        });
    }

    ngOnInit(): void {
        this.filePassed = false;
        this.data = this._storageService.getCurrentSearchData();
        this.dataName = this._storageService.getCurrentSearchNameData();
        if (this.data === null) {
            this._router.navigate(['/']);
        }
        this.form = this.fb.group({
            file: new FormControl(null, [Validators.required]),
        });
        this.formErrors = this.fb.group({
            errors: [null, [Validators.required]],
        });
    }

    onFileSelected(event: Event): void {
        const file = (event.target as HTMLInputElement).files[0];
        if (file) {
            this.fileName = file.name;  // Update the UI to show the file name
            this.form.get('file').setValue(file);
        }
    }

    triggerFileInput(): void {
        this.fileInput.nativeElement.click();  // Trigger the file input dialog
    }

    removeError(error: string): void {
        const index = this.errors.indexOf(error);

        if (index >= 0) {
            this.errors.splice(index, 1);
        }
        // remove the isSelect value from the hex values
        const descriptionsTable = this.signatureData.maps.find(x => x.mapType === MapType.DtcDescriptions);
        descriptionsTable.hexValues.forEach(element => {
            if (element.value === error) {
                element.isSelect = false;
                element.isScrolled = false;
            }
        });
    }

    onErrorClick(error: string): void {
        if (this.lastErrorClicked !== error) {
            this.lastErrorIndexScrolledManufacturer = null;
            this.lastErrorIndexScrolledDescriptions = null;
            this.currentTable = 'manufacturer';
        }

        const manufacturerDescriptionsTable = this.signatureData.maps.find(
            (x) => x.mapType === MapType.DtcDescriptionsManufacturer
        );
        const descriptionsTable = this.signatureData.maps.find(
            (x) => x.mapType === MapType.DtcDescriptions
        );

        const manufacturerHexValues = manufacturerDescriptionsTable?.hexValues || [];
        const descriptionsHexValues = descriptionsTable?.hexValues || [];

        let foundIndex = -1;

        // Procurar na tabela atual
        if (this.currentTable === 'manufacturer') {
            foundIndex = manufacturerHexValues.findIndex((element, i) => {
                if (
                    element.value === error &&
                    (this.lastErrorIndexScrolledManufacturer === null ||
                        i > this.lastErrorIndexScrolledManufacturer)
                ) {
                    return true;
                }
                return false;
            });

            if (foundIndex !== -1) {
                // Encontrado em DtcDescriptionsManufacturer
                this.lastErrorIndexScrolledManufacturer = foundIndex;
            } else {
                // Não há mais ocorrências em Manufacturer, mudar para Descriptions
                this.currentTable = 'descriptions';
                this.lastErrorIndexScrolledDescriptions = null;
            }
        }

        if (this.currentTable === 'descriptions') {
            foundIndex = descriptionsHexValues.findIndex((element, i) => {
                if (
                    element.value === error &&
                    (this.lastErrorIndexScrolledDescriptions === null ||
                        i > this.lastErrorIndexScrolledDescriptions)
                ) {
                    return true;
                }
                return false;
            });

            if (foundIndex !== -1) {
                // Encontrado em DtcDescriptions
                this.lastErrorIndexScrolledDescriptions = foundIndex;
            } else {
                // Não há mais ocorrências em Descriptions, voltar para o primeiro em Manufacturer
                this.currentTable = 'manufacturer';
                this.lastErrorIndexScrolledManufacturer = null;

                foundIndex = manufacturerHexValues.findIndex(
                    (element) => element.value === error
                );

                if (foundIndex !== -1) {
                    this.lastErrorIndexScrolledManufacturer = foundIndex;
                } else {
                    // Erro não encontrado em nenhuma tabela
                    return;
                }
            }
        }

        // Agora, fazer o scroll até foundIndex na tabela atual
        const currentMapType =
            this.currentTable === 'manufacturer'
                ? MapType.DtcDescriptionsManufacturer
                : MapType.DtcDescriptions;

        // Encontrar o container correspondente ao MapType atual
        const containerRef = this.mapContainers.find((container) => {
            const mapTypeAttribute = container.nativeElement.getAttribute(
                'data-map-type'
            );
            return mapTypeAttribute === currentMapType.toString();
        });

        if (!containerRef) {
            console.error('Contêiner não encontrado para MapType:', currentMapType);
            return;
        }

        const container = containerRef.nativeElement;

        // Acessar o elemento alvo corretamente
        const hexValuesContainer = container.querySelector('.hex-values');
        if (!hexValuesContainer) {
            console.error('hex-values não encontrado no contêiner.');
            return;
        }

        const targetElement = hexValuesContainer.children[foundIndex] as HTMLElement;
        if (!targetElement) {
            console.error('Elemento alvo não encontrado para o índice:', foundIndex);
            return;
        }

        // Realizar o scroll
        const containerWidth = container.clientWidth;
        const elementWidth = targetElement.clientWidth;
        const elementLeft = targetElement.offsetLeft;
        const elementCenter = elementLeft + elementWidth / 2;
        const scrollLeft = Math.max(0, elementCenter - containerWidth / 2);
        container.scrollLeft = scrollLeft;

        // Atualizar isScrolled
        if (this.currentTable === 'manufacturer') {
            manufacturerHexValues.forEach((element, i) => {
                element.isScrolled = i === this.lastErrorIndexScrolledManufacturer;
            });
            descriptionsHexValues.forEach((element) => (element.isScrolled = false));
        } else {
            descriptionsHexValues.forEach((element, i) => {
                element.isScrolled = i === this.lastErrorIndexScrolledDescriptions;
            });
            manufacturerHexValues.forEach((element) => (element.isScrolled = false));
        }

        this.lastErrorClicked = error;
    }



    addError(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        const descriptionsTable = this.signatureData.maps.find(x => x.mapType === MapType.DtcDescriptions);
        const manufacturerDescriptionsTable = this.signatureData.maps.find(x => x.mapType === MapType.DtcDescriptionsManufacturer);

        const descriptions = descriptionsTable ? descriptionsTable.hex.split(' ') : [];
        const manufacturerDescriptions = manufacturerDescriptionsTable ? manufacturerDescriptionsTable.hex.split(' ') : [];

        let found = false;

        // Procurar o valor nas duas tabelas
        descriptions.forEach(element => {
            if (element === value) {
                found = true;
            }
        });

        manufacturerDescriptions.forEach(element => {
            if (element === value) {
                found = true;
            }
        });

        if (!found) {
            this.dialog.open(DeleteDialogComponent, {
                data: {
                    title: 'Error not found',
                    hasOkButton: true
                }
            });
            event.chipInput!.clear();
            return;
        }

        if (value) {
            this.errors.push(value);
            // set the value to the form control joined by a comma
            this.formErrors.get('errors').setValue(this.errors.join(','));

            // Definir como selecionado o valor na tabela de descriptions (se encontrado)
            descriptionsTable?.hexValues.forEach(element => {
                if (element.value === value) {
                    element.isSelect = true;
                }
            });

            // Definir como selecionado o valor na tabela de manufacturer descriptions (se encontrado)
            manufacturerDescriptionsTable?.hexValues.forEach(element => {
                if (element.value === value) {
                    element.isSelect = true;
                }
            });
        }

        // Clear the input value
        event.chipInput!.clear();
    }

    getMapTypeName(mapType: MapType): string {
        return this._enumService.getMapTypeName(mapType);
    }

    syncScroll(event: Event): void {
        const source = event.target as HTMLDivElement;
        const {scrollLeft} = source;

        this.mapContainers.forEach((container) => {
            const target = container.nativeElement;
            if (target !== source) {
                target.scrollLeft = scrollLeft;
            }
        });
    }

    save() {
        if (this.filePassed) {
            if (this.formErrors.valid) {
                const formData = new FormData();
                formData.append('errors', this.formErrors.get('errors').value);
                formData.append('brandId', this.data.brandId);
                formData.append('ecuProducerId', this.data.ecuProducerId);
                formData.append('ecuTypeId', this.data.ecuTypeId);
                formData.append('file', this.form.get('file').value);

                // submit and download the file
                this.isLoadingSpinnerActive = true;

                this._dtcModuleService.submit(formData).pipe(
                    // Handle errors
                    catchError((error: any) => {
                        this.isLoadingSpinnerActive = false;

                        // Log error
                        console.error('Submission error:', error);

                        // Handle specific error codes (e.g., 400, 500, etc.)
                        if (error.status === 400) {
                            // add a dialog with error message
                            alert('Invalid data.');
                        } else if (error.status === 415) {
                            alert('Unsupported Media Type: Please upload a valid file format.');
                        } else {
                            alert('An error occurred while submitting the form. Please try again.');
                        }

                        // Return an empty observable to stop further processing
                        return of(null);
                    })
                ).subscribe((response) => {
                    this.isLoadingSpinnerActive = false;

                    if (response.type === 4) {
                        const fileName = `${this.fileName}_errors_${this.formErrors.get('errors').value}.bin`;
                        const blob = new Blob([response.body], {type: 'application/octet-stream'});
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this._storageService.clearCurrentSearchData();
                        this._router.navigate(['/']);
                    }
                });
            }
        } else {
            if (this.form.valid) {
                const formData = new FormData();
                formData.append('brandId', this.data.brandId);
                formData.append('ecuProducerId', this.data.ecuProducerId);
                formData.append('ecuTypeId', this.data.ecuTypeId);
                formData.append('file', this.form.get('file').value);

                // submit and download the file
                this.isLoadingSpinnerActive = true;

                this._dtcModuleService.checkFile(formData).pipe(
                    // Handle errors
                    catchError((error: any) => {
                        this.isLoadingSpinnerActive = false;

                        // Handle specific error codes (e.g., 400, 500, etc.)
                        if (error.status === 400) {
                            // add a dialog with error message
                            if (error.error.message === 'No solutions for that file') {

                                const dialogRef = this.dialog.open(DeleteDialogComponent, {
                                    data: {
                                        title: 'No solutions for that file. Do you want to send the file to support?',
                                        hasYesButton: true,
                                        hasNoButton: true,
                                    }
                                });

                                dialogRef.afterClosed().subscribe(result => {
                                    if (result === 'yes') {
                                        // send the file to support via api
                                        this.isLoadingSpinnerActive = true;

                                        this._dtcModuleService.sendToSupport(formData).pipe(
                                            catchError((error: any) => {
                                                this.isLoadingSpinnerActive = false;
                                                console.error('Submission error:', error);
                                                return of(null);
                                            })
                                        ).subscribe((response) => {
                                            this.isLoadingSpinnerActive = false;
                                            if (response.type === 4) {
                                                this.dialog.open(DeleteDialogComponent, {
                                                    data: {
                                                        title: 'The file has been sent to support',
                                                        hasOkButton: true
                                                    }
                                                });
                                                // Redirect to the home page
                                                this._storageService.clearCurrentSearchData();
                                                this._router.navigate(['/']);
                                            }
                                        });
                                    }
                                });
                            } else {
                                this.dialog.open(DeleteDialogComponent, {
                                    data: {
                                        title: 'Invalid data',
                                        hasOkButton: true
                                    }
                                });
                            }
                        } else if (error.status === 415) {
                            this.dialog.open(DeleteDialogComponent, {
                                data: {
                                    title: 'Unsupported Media Type: Please upload a valid file format',
                                    hasOkButton: true
                                }
                            });
                        } else {
                            this.dialog.open(DeleteDialogComponent, {
                                data: {
                                    title: 'An error occurred while submitting the form. Please try again',
                                    hasOkButton: true
                                }
                            });
                        }

                        // Return an empty observable to stop further processing
                        return of(null);
                    })
                ).subscribe((response) => {
                    // process the response events here
                    if (response.type === 4) {
                        this.filePassed = true;
                        this.isLoadingSpinnerActive = false;
                        this.signatureData = response.body;

                        const customOrder = [
                            MapType.DtcDescriptionsManufacturer,
                            MapType.DtcDescriptions,
                            MapType.DtcClasses,
                            MapType.DtcMasks
                        ];
                        this.signatureData.maps = this.signatureData.maps.sort((a, b) => {
                            return customOrder.indexOf(a.mapType) - customOrder.indexOf(b.mapType);
                        });

                        this.signatureData.maps.forEach(element => {
                            element.hexValues = element.hex.split(' ').map((value) => {
                                return {value, isSelect: false, isScrolled: false};
                            });
                        });
                    }
                });
            }
        }
    }
}
