<h1 mat-dialog-title class="custom-dialog-title" *ngIf="data.title">{{data.title}}</h1>
<!-- Add type dialog with icons -->
<div mat-dialog-content class="text-center">
    <mat-icon *ngIf="data.type === 'info'" class="info-icon">info</mat-icon>
    <mat-icon *ngIf="data.type === 'warning'" class="warning-icon">warning</mat-icon>
    <mat-icon *ngIf="data.type === 'error'" class="error-icon">error</mat-icon>
    <mat-icon *ngIf="data.type === 'success'" class="success-icon">check_circle</mat-icon>
    {{data.description}} <span *ngIf="data.item">"{{data.item}}"</span>
</div>
<div mat-dialog-actions class="custom-dialog-actions">
    <button mat-flat-button
            type="button"
            [color]="'warn'"
            [mat-dialog-close]="'cancel'"
            *ngIf="data.hasCancelButton">
        Cancel
    </button>
    <button mat-flat-button
            type="button"
            [color]="'primary'"
            [mat-dialog-close]="'ok'"
            cdkFocusInitial
            *ngIf="data.hasOkButton">Ok
    </button>
    <button mat-flat-button
            type="button"
            [color]="'primary'"
            [mat-dialog-close]="'yes'"
            cdkFocusInitial
            *ngIf="data.hasYesButton"
    >Yes
    </button>
    <button mat-flat-button
            [color]="'warn'"
            [mat-dialog-close]="'no'"
            *ngIf="data.hasNoButton">No
    </button>
</div>
