import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeleteDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
        title: string,
        description: string,
        item: string,
        hasOkButton: boolean,
        hasCancelButton: boolean,
        hasYesButton: boolean,
        hasNoButton: boolean,
        type: string,
    }) {
    }

}
